import { default as _91_46_46_46slug_93gSR0piH53FMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/[...slug].vue?macro=true";
import { default as adressen1wvRASq4NPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as indexBmBgLgJ0BwMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkerspteK2qihY7Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexAyU2zgx517Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as indexTvXdAiGvzeMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/communicatievoorkeuren/index.vue?macro=true";
import { default as indexRVWIluAldzMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_937Z8IJrr99fMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexYwOTjCMyW8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/facturen/index.vue?macro=true";
import { default as indexsV4Y2MdxyuMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/index.vue?macro=true";
import { default as indexDTvhMoS9m3Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incasso6VZPe4vhoQMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as index7Ut7YD6V57Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as indexxpXMUWaJGoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/lidmaatschap/index.vue?macro=true";
import { default as nieuwsbriefvoorkeurenJMLpz7O7QnMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/nieuwsbriefvoorkeuren.vue?macro=true";
import { default as accountvUKLVIE7PGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account.vue?macro=true";
import { default as login7rGK4ZpKjpMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/login.vue?macro=true";
import { default as _91token_93d4MgAzzyC1Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93RMINQtPwIIMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as indexgBfA9Ohh4iMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_93mFZ8PfNxw4Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurdersacademie4qApcUK8FcMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/bestuurdersacademie.vue?macro=true";
import { default as dossiers_45documentenEJjz2BP5YZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexhDbkWRGdMtMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/index.vue?macro=true";
import { default as bestuurders9TUDBWivpiMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders.vue?macro=true";
import { default as dashboardlkYIclCDY3Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/dashboard.vue?macro=true";
import { default as aanmeldenB9Ct4HEuzaMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen/[slug]/aanmelden.vue?macro=true";
import { default as indexAyg9JZaZ0bMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen/[slug]/index.vue?macro=true";
import { default as indexp9bTxfLQdbMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen/index.vue?macro=true";
import { default as evenementen1BAwzzqqPUMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen.vue?macro=true";
import { default as indexIIpqTkqhKzMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/index.vue?macro=true";
import { default as kennisbank8ETKngJAtrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/kennisbank.vue?macro=true";
import { default as _91id_93xwmXA20qb6Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/klanten/[id].vue?macro=true";
import { default as index3GwdZKLnCOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/klanten/index.vue?macro=true";
import { default as afnemen9NyxTu1lsVMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexjDVKJJfq6nMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexQv5lWX9Y6XMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelen6ltNt9yiDoMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen.vue?macro=true";
import { default as indexyvQO0sPHMPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvenqQVYAB6ghFMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorize8rjiAYTMxpMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/oauth/authorize.vue?macro=true";
import { default as stylesFvFGFGs4ywMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/styles.vue?macro=true";
import { default as _91id_93z6ZLCCFUf7Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexEasomdKz7sMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93z9EIUuEsZqMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexITJE3isu6nMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/[category]/index.vue?macro=true";
import { default as cartE0f3KNzcjEMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/cart.vue?macro=true";
import { default as checkoutqqOuz5B6FDMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/checkout.vue?macro=true";
import { default as confirmationWiKkF0m1YiMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/confirmation.vue?macro=true";
import { default as indexlTcnpRveNrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/index.vue?macro=true";
import { default as webshopwBfksbWp8ZMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop.vue?macro=true";
import { default as zoeken1PpwHFOZXrMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/zoeken.vue?macro=true";
import { default as indexD704OlaQYNMeta } from "~/pages/evenementen/[slug]/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93gSR0piH53FMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: accountvUKLVIE7PGMeta?.name,
    path: "/account",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-bedrijfsgegevens-company-adressen",
    path: "bedrijfsgegevens/:company()/adressen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/[company]/adressen.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens-company",
    path: "bedrijfsgegevens/:company()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/[company]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens-company-medewerkers",
    path: "bedrijfsgegevens/:company()/medewerkers",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/[company]/medewerkers.vue").then(m => m.default || m)
  },
  {
    name: "account-bedrijfsgegevens",
    path: "bedrijfsgegevens",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/bedrijfsgegevens/index.vue").then(m => m.default || m)
  },
  {
    name: "account-communicatievoorkeuren",
    path: "communicatievoorkeuren",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/communicatievoorkeuren/index.vue").then(m => m.default || m)
  },
  {
    name: "account-evenementen",
    path: "evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: "account-facturen-factuur",
    path: "facturen/:factuur()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/facturen/[factuur].vue").then(m => m.default || m)
  },
  {
    name: "account-facturen",
    path: "facturen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/facturen/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    meta: indexsV4Y2MdxyuMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-ledenvoordelen",
    path: "ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/ledenvoordelen/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap-lidmaatschap-incasso",
    path: "lidmaatschap/:lidmaatschap()/incasso",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap-lidmaatschap",
    path: "lidmaatschap/:lidmaatschap()",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/lidmaatschap/[lidmaatschap]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-lidmaatschap",
    path: "lidmaatschap",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/lidmaatschap/index.vue").then(m => m.default || m)
  },
  {
    name: "account-nieuwsbriefvoorkeuren",
    path: "nieuwsbriefvoorkeuren",
    meta: nieuwsbriefvoorkeurenJMLpz7O7QnMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/account/nieuwsbriefvoorkeuren.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login7rGK4ZpKjpMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93d4MgAzzyC1Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-vergeten-token",
    path: "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93RMINQtPwIIMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/wachtwoord-vergeten/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-vergeten",
    path: "/auth/wachtwoord-vergeten",
    meta: indexgBfA9Ohh4iMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/auth/wachtwoord-vergeten/index.vue").then(m => m.default || m)
  },
  {
    name: bestuurders9TUDBWivpiMeta?.name,
    path: "/bestuurders",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders.vue").then(m => m.default || m),
    children: [
  {
    name: "bestuurders-slug",
    path: ":slug()",
    meta: _91slug_93mFZ8PfNxw4Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-bestuurdersacademie",
    path: "bestuurdersacademie",
    meta: bestuurdersacademie4qApcUK8FcMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/bestuurdersacademie.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-dossiers-documenten",
    path: "dossiers-documenten",
    meta: dossiers_45documentenEJjz2BP5YZMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/dossiers-documenten.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders",
    path: "",
    meta: indexhDbkWRGdMtMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/bestuurders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: evenementen1BAwzzqqPUMeta?.name,
    path: "/evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen.vue").then(m => m.default || m),
    children: [
  {
    name: "evenementen-slug-aanmelden",
    path: ":slug()/aanmelden",
    meta: aanmeldenB9Ct4HEuzaMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen/[slug]/aanmelden.vue").then(m => m.default || m)
  },
  {
    name: "evenementen-slug",
    path: ":slug()",
    meta: indexAyg9JZaZ0bMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "evenementen",
    path: "",
    meta: indexp9bTxfLQdbMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/evenementen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexIIpqTkqhKzMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kennisbank",
    path: "/kennisbank",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/kennisbank.vue").then(m => m.default || m)
  },
  {
    name: "klanten-id",
    path: "/klanten/:id()",
    meta: _91id_93xwmXA20qb6Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/klanten/[id].vue").then(m => m.default || m)
  },
  {
    name: "klanten",
    path: "/klanten",
    meta: index3GwdZKLnCOMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: ledenvoordelen6ltNt9yiDoMeta?.name,
    path: "/ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen.vue").then(m => m.default || m),
    children: [
  {
    name: "ledenvoordelen-slug-afnemen",
    path: ":slug()/afnemen",
    meta: afnemen9NyxTu1lsVMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen/[slug]/afnemen.vue").then(m => m.default || m)
  },
  {
    name: "ledenvoordelen-slug",
    path: ":slug()",
    meta: indexjDVKJJfq6nMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "ledenvoordelen",
    path: "",
    meta: indexQv5lWX9Y6XMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/ledenvoordelen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "lid-worden",
    path: "/lid-worden",
    meta: indexyvQO0sPHMPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/lid-worden/index.vue").then(m => m.default || m)
  },
  {
    name: "nieuwsbrief-uitschrijven",
    path: "/nieuwsbrief/uitschrijven",
    meta: uitschrijvenqQVYAB6ghFMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/nieuwsbrief/uitschrijven.vue").then(m => m.default || m)
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorize8rjiAYTMxpMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/oauth/authorize.vue").then(m => m.default || m)
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/styles.vue").then(m => m.default || m)
  },
  {
    name: "verkoopkansen-id",
    path: "/verkoopkansen/:id()",
    meta: _91id_93z6ZLCCFUf7Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/verkoopkansen/[id].vue").then(m => m.default || m)
  },
  {
    name: "verkoopkansen",
    path: "/verkoopkansen",
    meta: indexEasomdKz7sMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/verkoopkansen/index.vue").then(m => m.default || m)
  },
  {
    name: webshopwBfksbWp8ZMeta?.name,
    path: "/webshop",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop.vue").then(m => m.default || m),
    children: [
  {
    name: "webshop-category-product",
    path: ":category()/:product()",
    meta: _91product_93z9EIUuEsZqMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/[category]/[product].vue").then(m => m.default || m)
  },
  {
    name: "webshop-category",
    path: ":category()",
    meta: indexITJE3isu6nMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "webshop-cart",
    path: "cart",
    meta: cartE0f3KNzcjEMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/cart.vue").then(m => m.default || m)
  },
  {
    name: "webshop-checkout",
    path: "checkout",
    meta: checkoutqqOuz5B6FDMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: "webshop-confirmation",
    path: "confirmation",
    meta: confirmationWiKkF0m1YiMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "webshop",
    path: "",
    meta: indexlTcnpRveNrMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/webshop/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "zoeken",
    path: "/zoeken",
    meta: zoeken1PpwHFOZXrMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/pages/zoeken.vue").then(m => m.default || m)
  },
  {
    name: "bestuurders-evenementen-slug",
    path: "/bestuurders/evenementen/:slug()",
    meta: indexD704OlaQYNMeta || {},
    component: () => import("~/pages/evenementen/[slug]/index.vue").then(m => m.default || m)
  }
]