import validate from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.23.1_magicast@0.3.5_nuxt@3.12_cmy33any4nwxrebmui65663pre/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import _01_45maintenance_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/middleware/01.maintenance.global.ts";
import _02_45active_45membership_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/middleware/02.activeMembership.global.ts";
import manifest_45route_45rule from "/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.1_encoding@0.1.13_eslint@8.57.0_ioredis@5._qq3i2pycoqd2mahik6t2ysz5tu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  _01_45maintenance_45global,
  _02_45active_45membership_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_esbuild@0.23.1_graphql-ws@5.16.0_graph_ujhozsuzekdyafrc3sjrc4j5bu/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  employee: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/middleware/employee.ts"),
  manager: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/middleware/manager.ts"),
  member: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/middleware/member.ts"),
  page: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/middleware/page.ts"),
  partner: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/middleware/partner.ts"),
  auth: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/43/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.1_encoding@0.1.13_magicast@0.3.5_next-auth@4.21.1_next@13.5.6_@babel+_2mqfur7cquz2ovppw3cwonrfde/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}